import React, { useState, useRef } from 'react'; 
import qr from './images/QR.png';
import whatsapp from './images/qr-whatsapp2.jpeg';
import { faFacebook, faXTwitter, faLinkedin, faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons';
import './Footer.css';
import MemberModal from './MemberModal';
import Home from './Home';
import menu from './images/menu.png';
import logo from './images/logo1.png';
import About from './About.jsx'; 
import Service from './Service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from './Footer';
import Contact from './Contact';
import MouseFollower from './MouseFollower'; // Import MouseFollower
import './Navbar.css';

const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State for the modal
  const [isOpen, setIsOpen] = useState(false);
  const [showIframe, setShowIframe] = useState(false); // State for toggling iframe visibility
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const serviceRef = useRef(null);
  const contactRef = useRef(null);
  

  // Function to toggle the navbar
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  // Function to scroll to the specific section
  const scrollToSection = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Function to toggle iframe visibility
  const toggleIframe = () => {
    setShowIframe(!showIframe);
  };

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>  
      <MouseFollower /> {/* Add the MouseFollower here */}
      <div className="floating-whatsapp">
        <a href="https://wa.me/918248816007?text=Hello,I need more info About your services" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faWhatsapp} size="2x" /> {/* WhatsApp Icon */}
        </a>
        <span className="tooltip-text">Need any help?</span>
      </div>

      {/* Button to toggle the iframe */}
      <div className="book-meeting-section">
        <button onClick={toggleIframe} className="book-button">
          {showIframe ? 'Close Meeting Scheduler' : 'Book a Consultation'}
        </button>
      </div>

      {/* Conditionally render the iframe */}
      {showIframe && (
        <div className="float-appointment">
          <iframe 
            src="https://calendly.com/contact-cibb/30min?back=1&month=2024-12" 
            className="calender" 
            title="Meeting Scheduler">
          </iframe>
        </div>
      )}

      <button className="toggle-btn" onClick={toggleNavbar}>
        {isOpen ? 'Close Menu' : 'Open Menu'} <img src={logo} className='logo-image' alt="Logo" /><img src={menu} className='hidden'/>
      </button> 

      <navbar className={`nav ${isOpen ? 'open' : ''}`}>
        <div className='Logo-name'>
          <div className='logo'>
            <img src={logo} className='logo-img' alt="Logo" />
          </div>
        </div>

        <div className='navbar'> 
          <ul className='nav-list'>
            <li className="nav-item" onClick={() => scrollToSection(homeRef)}>
              <i className="fa-solid fa-address-card"></i> Home
            </li>
            <li className="nav-item" onClick={() => scrollToSection(aboutRef)}>
              <i className="fa-solid fa-address-card"></i> About
            </li>
            <li className="nav-item" onClick={() => scrollToSection(serviceRef)}>
              <i className="fa-solid fa-address-card"></i> Services
            </li>
            <li className="nav-item" onClick={() => scrollToSection(contactRef)}>
              <i className="fa-solid fa-address-card"></i> Contact
            </li>
          </ul>
        </div>
      </navbar>

      <center>
        <div className='tag-line-navbar'>
          <h1 className='tag-navbar'>Bridging China and India for Mutual Growth and Global Success</h1>
        </div>
      </center>

      <section ref={homeRef}><Home /></section>
      <section ref={aboutRef}><About /></section>
      <section ref={serviceRef}><Service /></section>
      <br/>
      <section ref={contactRef}><Contact /></section>
      <footer className='footer-whole'>
      <div className='footer-container'>
        <div>
          <img src={logo} className='logo-image-footer' alt="Logo" />
        </div>

        <div className='footer-column-1'>
          <h3>Navigate</h3>
          <ul className='footer-list'>
            <li onClick={() => scrollToSection(homeRef)}>Home</li>
            <li onClick={() => scrollToSection(aboutRef)}>About Us</li>
            <li onClick={() => scrollToSection(serviceRef)}>Services</li>
            <li onClick={() => scrollToSection(contactRef)}>Contact Us</li>
            <li className="footer-link adver" ><a target='blank' href="https://docs.google.com/forms/d/e/1FAIpQLSf2VQV1Ksb6l3BoGZ1FIou1ArZ34fKdcPQgAH35HUl7pQNoag/viewform" className='link-member'>Become a Member</a></li>
          </ul>
        </div>

        <div className='footer-column-2'>
          <h3>Follow Us</h3>
          <ul className='footer-list'>
          <li><a href="" className='social-icon twitter'><FontAwesomeIcon icon={faXTwitter} /> Twitter</a></li>  
        
      
          <li><a href="https://www.linkedin.com/company/chinaindiabusinessbridge/"  target="blank" className='social-icon linkedin'><FontAwesomeIcon icon={faLinkedin} /> Linkedin</a></li>
            <li><a href="https://www.facebook.com/profile.php?id=61569297234852" target="blank" className='social-icon facebook'><FontAwesomeIcon icon={faFacebook} /> Facebook</a></li>
            <li><a href="https://www.instagram.com/cibb_network/"  target="blank" className='social-icon linkedin'><FontAwesomeIcon icon={faInstagram} /> Instagram</a></li>
          </ul>
        </div>
        <div className='footer-column-2'>
          <h3>Contact</h3>
          <ul className='footer-list'>
            <li>Address:3Rd Floor, Rams apartment, 13/53, 100 Feet Rd,
               near RBL BANK, Sector 10, Sector 13, Ashok Nagar, Chennai,
                Tamil Nadu 600083</li>
            <li>Email: <a href="mailto:contact@cibb.in" className='a'>contact@cibb.in</a></li>
            <li>Phone: +91 8248816007 </li>
           <div className='footer-flex-1'> <div><li className='width'>Wechat id:</li>
            <li className='width'><img src={qr} alt="we chat cibb qr" className='qr'/></li></div><div> <li className='width'>WhatsApp id:</li>
            <li className='width'><img src={whatsapp} alt="we chat cibb qr" className='qr'/></li></div></div>
            {/* <li><a href="/contact" className='footer-link'>Contact Form</a></li> */}
          </ul>
        </div>
      </div>

      <div className='footer-bottom'>
        <p>&copy; {new Date().getFullYear()} CIBB. All Rights Reserved.</p>
      </div>{isModalOpen && <MemberModal onClose={closeModal} />}
    </footer>
  {/* Conditionally render the modal */}
  {isModalOpen && <MemberModal onClose={closeModal} />}
    </div>
    
  );
}

export default App;
